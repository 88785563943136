import React, { useState } from "react"
import css from "@styled-system/css"

import {
  Box,
  Button,
  FancyLink,
  Flex,
  Heading,
  Input,
  Tabs,
  Text,
  Textarea,
  Wrapper,
} from "components"

// Simple wrapper for phone numbers to keep styling consistent
const PhoneNumber = ({ label, number }) => (
  <Box as="li">
    <Flex>
      <Text
        as="h6"
        children={label}
        size={500}
        mr="spacing.2"
        lineHeight={[24 + "px", 32 + "px"]}
        color="alt"
      />
      <Text size={500} lineHeight={[24 + "px", 32 + "px"]}>
        <FancyLink children={number} as="a" href={"tel:" + number} />
      </Text>
    </Flex>
  </Box>
)

function Form({ data }) {
  const [fileReady, setFileReady] = useState(false)
  return (
    <Box bg="wash" width={["100%", "calc(100% - 64px)"]} mx="auto">
      <Wrapper py={["layout.7", "layout.8"]}>
        {/* Heading Text */}
        {data.heading && (
          <Heading children={data.heading} mb={["layout.2", "layout.4"]} />
        )}
        <Tabs
          tabs={[
            {
              label: "General",
              content: (
                <Box
                  mt="layout.4"
                  name="contact"
                  method="POST"
                  as="form"
                  action="/thank-you/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
                  <Flex
                    flexWrap="wrap"
                    m={-16}
                    css={css({
                      "> *": {
                        width: ["100%", null, "50%"],
                        p: 16,
                      },
                    })}
                  >
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-name"
                        name="name"
                        label="Name"
                        required="true"
                        placeholder="Your full name"
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-email"
                        name="email"
                        label="Email"
                        required="true"
                        placeholder="Your email"
                      />
                    </Box>
                    <Box>
                      <Input // OPTIONAL FIELD
                        id="form-company"
                        name="company"
                        label="Company"
                        placeholder="Your company"
                      />
                    </Box>
                    <Box>
                      <Input // OPTIONAL FIELD
                        id="form-phone"
                        name="phone"
                        label="Phone"
                        type="tel"
                        placeholder="Your phone number"
                      />
                    </Box>
                    <Box>
                      <Textarea // REQUIRED FIELD
                        id="form-message"
                        name="message"
                        label="Message"
                        rows="3"
                        required="true"
                        placeholder="A short message explaining what you are looking for"
                      />
                    </Box>
                  </Flex>
                  <Box mt={["layout.2", "layout.4"]}>
                    <Button children="Submit inquiry" type="submit" />
                  </Box>
                  {/* <Box mt={["layout.2", "layout.4"]}>
                    <div data-netlify-recaptcha="true"></div>
                  </Box> */}
                </Box>
              ),
            },
            // {
            //   label: "Shareholder",
            //   content: (
            //     <Box
            //       mt="layout.4"
            //       name="contact"
            //       method="POST"
            //       as="form"
            //       action="/thank-you/"
            //       data-netlify="true"
            //       data-netlify-honeypot="bot-field"
            //     >
            //       <input type="hidden" name="form-name" value="contact" />
            //       {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
            //       <Flex
            //         flexWrap="wrap"
            //         m={-16}
            //         css={css({
            //           "> *": {
            //             width: ["100%", null, "50%"],
            //             p: 16,
            //           },
            //         })}
            //       >
            //         <Box>
            //           <Input // REQUIRED FIELD
            //             id="form-name"
            //             name="name"
            //             label="Name"
            //             required="true"
            //             placeholder="Your full name"
            //           />
            //         </Box>
            //         <Box>
            //           <Input // REQUIRED FIELD
            //             id="form-email"
            //             name="email"
            //             label="Email"
            //             required="true"
            //             placeholder="Your email"
            //           />
            //         </Box>
            //         <Box>
            //           <Input // OPTIONAL FIELD
            //             id="form-company"
            //             name="company"
            //             label="Company"
            //             placeholder="Your company"
            //           />
            //         </Box>
            //         <Box>
            //           <Input // OPTIONAL FIELD
            //             id="form-phone"
            //             name="phone"
            //             label="Phone"
            //             type="tel"
            //             placeholder="Your phone number"
            //           />
            //         </Box>
            //         <Box>
            //           <Textarea // REQUIRED FIELD
            //             id="form-message"
            //             name="message"
            //             label="Message"
            //             rows="3"
            //             required="true"
            //             placeholder="A short message explaining what you are looking for"
            //           />
            //         </Box>
            //       </Flex>
            //       <Box mt={["layout.2", "layout.4"]}>
            //         <Button children="Submit inquiry" type="submit" />
            //       </Box>
            //     </Box>
            //   ),
            // },
            {
              label: "Senior Notes",
              content: (
                <Box
                  mt="layout.4"
                  name="seniorNotes"
                  method="POST"
                  as="form"
                  action="/thank-you/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="seniorNotes" />
                  <Text mb="layout.2">
                    If you are a holder of Teine's senior notes and would like
                    to request information, please complete the form below to
                    submit your request.
                  </Text>
                  {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
                  <Flex
                    flexWrap="wrap"
                    m={-16}
                    css={css({
                      "> *": {
                        width: ["100%", null, "50%"],
                        p: 16,
                      },
                    })}
                  >
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-name"
                        name="name"
                        label="Name"
                        required="true"
                        placeholder="Your full name"
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-email"
                        name="email"
                        label="Email"
                        required="true"
                        placeholder="Your email"
                      />
                    </Box>
                    <Box>
                      <Input // OPTIONAL FIELD
                        id="form-company"
                        name="company"
                        label="Company"
                        placeholder="Your company"
                      />
                    </Box>
                    <Box>
                      <Input // OPTIONAL FIELD
                        id="form-phone"
                        name="phone"
                        label="Phone"
                        type="tel"
                        placeholder="Your phone number"
                      />
                    </Box>
                    <Box>
                      <Textarea // REQUIRED FIELD
                        id="form-message"
                        name="message"
                        label="Message"
                        rows="3"
                        required="true"
                        placeholder="A short message explaining what you are looking for"
                      />
                    </Box>
                  </Flex>
                  <Box mt={["layout.2", "layout.4"]}>
                    <Button children="Submit inquiry" type="submit" />
                  </Box>
                  {/* <Box mt={["layout.2", "layout.4"]}>
                    <div data-netlify-recaptcha="true"></div>
                  </Box> */}
                </Box>
              ),
            },
            {
              label: "Careers",
              content: (
                <Box
                  mt="layout.4"
                  name="careersForm"
                  encType="multipart/form-data"
                  method="POST"
                  as="form"
                  action="/thank-you/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="careersForm" />
                  <Text mb="layout.2">
                    We appreciate the interest of all applicants, however, only
                    those candidates to be interviewed will be contacted.
                  </Text>

                  {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
                  <Flex
                    flexWrap="wrap"
                    m={-16}
                    css={css({
                      "> *": {
                        width: ["100%", null, "50%"],
                        p: 16,
                      },
                    })}
                  >
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-jobTitle"
                        name="jobTitle"
                        label="Job Title"
                        required="true"
                        placeholder="Job title you're applying for."
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-name"
                        name="name"
                        label="Name"
                        required="true"
                        placeholder="Your full name"
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-email"
                        name="email"
                        label="Email"
                        required="true"
                        placeholder="Your email"
                      />
                    </Box>
                    <Box>
                      <Input // REQUIRED FIELD
                        id="form-phone"
                        name="phone"
                        label="Phone"
                        type="tel"
                        required="true"
                        placeholder="Your phone number"
                      />
                    </Box>
                    <Box>
                      <Heading size={100} mb="layout.1">
                        Resume
                      </Heading>
                      <Text as="div">
                        <input
                          type="file"
                          name="resume"
                          required="true"
                          // onChange={() => {
                          //   setFileReady(true)
                          // }}
                        />
                      </Text>
                    </Box>
                    {/* <Box>
                      <Heading size={100} mb="layout.1">
                        Additional Documents (OPTIONAL)
                      </Heading>
                      <Text as="div">
                        <input
                          type="file"
                          name="additionalDocuments"
                          // onChange={() => {
                          //   setFileReady(true)
                          // }}
                        />
                      </Text>
                    </Box> */}
                  </Flex>
                  <Box mt={["layout.2", "layout.4"]}>
                    <Button
                      children="Submit inquiry"
                      type="submit"
                      // disabled={!fileReady ? true : false}
                    />
                  </Box>
                  {/* <Box mt={["layout.2", "layout.4"]}>
                    <div data-netlify-recaptcha="true"></div>
                  </Box> */}
                </Box>
              ),
            },
            (data.confidenceLineNumber || data.confidenceLineSite) && {
              label: "ConfidenceLine",
              content: (
                <Box py={"layout.4"}>
                  {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
                  <Flex
                    flexWrap="wrap"
                    m={[-16, null, -32]}
                    css={css({
                      "> *": {
                        width: ["100%", null, "50%"],
                        p: [16, null, 32],
                      },
                    })}
                  >
                    <Box>
                      <Heading
                        children={"ConfidenceLine Website"}
                        size={600}
                        mb={["layout.2", "layout.3"]}
                      />
                      <FancyLink href={data.confidenceLineSite}>
                        <Text
                          as="span"
                          size={500}
                          lineHeight={[24 + "px", 32 + "px"]}
                          children={data.confidenceLineSite}
                          style={{ cursor: "pointer" }}
                        />
                      </FancyLink>
                    </Box>
                    {/* Phone, Fax and Emergency. */}
                    <Box>
                      <Heading
                        children={"ConfidenceLine Phone Number"}
                        size={600}
                        mb={["layout.2", "layout.3"]}
                      />
                      <Box as="ul" px={0} m={0} css={{ listStyle: "none" }}>
                        {data.confidenceLineNumber && (
                          <PhoneNumber
                            label="North America"
                            number={data.confidenceLineNumber}
                          />
                        )}
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              ),
            },
          ]}
        />
      </Wrapper>
    </Box>
  )
}

export default Form
