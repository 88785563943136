import React from "react"
import css from "@styled-system/css"
import Sticky from "react-stickynode"

import {
  AccordionGroup,
  Box,
  ColorMode,
  Heading,
  FancyLink,
  Flex,
  RichText,
  Text,
  Wrapper,
} from "components"

const CorporateInfo = ({ data }) => (
  <>
    {data.contacts && data.contacts[0] && data.heading && (
      <ColorMode mode="dark">
        <Box
          bg="background"
          width={["100%", "calc(100% - 64px)"]}
          mx="auto"
          mt={[0, "layout.4"]}
        >
          <Wrapper pt={["layout.5", "layout.6"]} pb={["layout.4", "layout.6"]}>
            <Flex
              flexWrap="wrap"
              m={[-16, null, -32]}
              css={css({
                "> *": {
                  width: ["100%", null, null, "50%"],
                  p: [16, null, 32],
                },
              })}
            >
              <Box display={["none", null, "block"]}>
                <Sticky bottomBoundary="#scrollableArea-corporateContacts">
                  <Box
                    pt={["layout.2", null, null, "layout.4"]}
                    pb={["layout.4", null, null, "layout.6"]}
                    width="100%"
                  >
                    <Heading
                      children={data.heading}
                      size={[800, null, null, 700]}
                    />
                  </Box>
                </Sticky>
              </Box>
              <Heading
                children={data.heading}
                size={[800, null, null, 700]}
                display={["block", null, "none"]}
              />
              {/* Corporate Contacts */}
              <Box id="scrollableArea-corporateContacts">
                <AccordionGroup
                  items={data.contacts.map(({ primary, items }) => ({
                    id: primary.category_title && primary.category_title.text,
                    heading:
                      primary.category_title && primary.category_title.text,
                    // subheading: title,
                    children: (
                      <>
                        {items &&
                          items.map((item, index) => (
                            <Box mb="layout.2">
                              <Text children={item.name} fontWeight="bold" />
                              {item.role && <Text children={item.role} />}
                              {item.address &&
                                item.address.html &&
                                item.address.text && (
                                  <RichText
                                    content={{ html: item.address.html }}
                                    my="layout.1"
                                  />
                                )}
                              {item.phone && (
                                <Flex>
                                  <Text
                                    as="h6"
                                    children={"Phone"}
                                    size={500}
                                    mr="spacing.2"
                                    color="alt"
                                  />
                                  <Text size={500}>{item.phone}</Text>
                                </Flex>
                              )}
                              {item.fax && (
                                <Flex>
                                  <Text
                                    as="h6"
                                    children={"Fax"}
                                    size={500}
                                    mr="spacing.2"
                                    color="alt"
                                  />
                                  <Text size={500}>{item.fax}</Text>
                                </Flex>
                              )}
                              {item.email && (
                                <Flex>
                                  <Text
                                    as="h6"
                                    children={"Email"}
                                    size={500}
                                    mr="spacing.2"
                                    color="alt"
                                  />
                                  <Text size={500}>{item.email}</Text>
                                </Flex>
                              )}
                              {item.website && item.website.url && (
                                <Flex>
                                  <Text
                                    as="h6"
                                    children={"Website"}
                                    size={500}
                                    mr="spacing.2"
                                    color="alt"
                                  />
                                  <Text>
                                    <FancyLink
                                      as="a"
                                      href={item.website.url}
                                      children={item.website.url}
                                    />
                                  </Text>
                                </Flex>
                              )}
                            </Box>
                          ))}
                      </>
                    ),
                  }))}
                />
              </Box>
            </Flex>
          </Wrapper>
        </Box>
      </ColorMode>
    )}
  </>
)

export default CorporateInfo
