import React from "react"
import css from "@styled-system/css"

import { Box, FancyLink, Flex, Heading, Text } from "components"

// Simple wrapper for phone numbers to keep styling consistent
const FormattedLink = ({ label, number, telLink = true }) => (
  <Box as="li">
    <Flex>
      {label && (
        <Text
          as="h6"
          children={label}
          size={500}
          mr="spacing.2"
          lineHeight={[24 + "px", 32 + "px"]}
          color="alt"
        />
      )}
      <Text size={500} lineHeight={[24 + "px", 32 + "px"]}>
        <FancyLink
          as="a"
          children={number}
          href={(telLink ? "tel:" : "") + number}
        />
      </Text>
    </Flex>
  </Box>
)

const Address = ({ data }) => (
  <>
    {/* Heading Text */}
    {data.heading && (
      <Heading
        children={data.heading}
        size={600}
        mb={["layout.2", "layout.3"]}
      />
    )}
    {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
    <Flex
      flexWrap="wrap"
      m={[-16, null, -32]}
      css={css({
        "> *": {
          width: ["100%", null, "50%"],
          p: [16, null, 32],
        },
      })}
    >
      <Box>
        {/* Head Office */}
        {/* Teine address. */}
        <Box as="address" css={{ fontStyle: "normal" }}>
          <a
            href={data.mapLink && data.mapLink.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.addressSuite && (
              <>
                <Text
                  as="span"
                  size={500}
                  lineHeight={[24 + "px", 32 + "px"]}
                  children={data.addressSuite}
                />
                <br />
              </>
            )}
            {data.addressStreet && (
              <>
                <Text
                  as="span"
                  size={500}
                  lineHeight={[24 + "px", 32 + "px"]}
                  children={data.addressStreet}
                />
                <br />
              </>
            )}
            {(data.addressPostal ||
              data.addressProvince ||
              data.addressCity) && (
              <Text
                as="span"
                size={500}
                lineHeight={[24 + "px", 32 + "px"]}
                children={
                  data.addressCity +
                  (data.addressProvince && ", " + data.addressProvince) +
                  (data.addressPostal && " " + data.addressPostal)
                }
              />
            )}
          </a>
        </Box>
      </Box>
      {/* Phone, Fax and Emergency. */}
      <Box>
        <Box as="ul" px={0} m={0} css={{ listStyle: "none" }}>
          {data.phoneNumber && (
            <FormattedLink label="Phone" number={data.phoneNumber} />
          )}
          {data.faxNumber && (
            <FormattedLink label="Fax" number={data.faxNumber} />
          )}
          {data.emergencyNumber && (
            <FormattedLink label="Emergency" number={data.emergencyNumber} />
          )}
          {data.confidenceLineNumber && (
            <FormattedLink
              label="ConfidenceLine"
              number={data.confidenceLineNumber}
            />
          )}
          {data.confidenceLineSite && (
            <FormattedLink
              label=""
              number={data.confidenceLineSite}
              telLink={false}
            />
          )}
        </Box>
      </Box>
    </Flex>
  </>
)

export default Address
