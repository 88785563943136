import React from "react"

import { Box, FadeWrapper, RichText, Wrapper } from "components"

import Address from "./_address"

const Intro = ({ data }) => (
  <Box
    className="page-title"
    as="section"
    pt={[128, 224]}
    mb={["layout.6", "layout.7"]}
  >
    {/* ~~~~~~~~~~ CONTENT ~~~~~~~~~~ */}
    <Wrapper position="relative">
      <FadeWrapper>
        <Box maxWidth={1080 / 1.5} mb={["layout.4", "layout.6"]}>
          {/* Heading Text */}
          {data.heading && (
            <RichText size={500} content={{ html: data.heading }} />
          )}
          {/* Text Block */}
          {data.description && (
            <RichText
              size={500}
              content={{ html: data.description }}
              maxWidth={1080 / 2}
              mt={data.heading && ["layout.2", "layout.4"]}
            />
          )}
        </Box>

        <Address data={data.address} />
      </FadeWrapper>
    </Wrapper>
  </Box>
)

export default Intro
